<template>
  <v-container fluid id="container" class="d-flex align-center justify-center">
    <v-row class="d-flex align-center">
      <v-col offset="1" offset-md="2" cols="10" md="8">
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <v-form role="resetPasswordForm" @submit.prevent="handleSubmit(resetPassword)">
            <v-row class="text-h4 font-weight-bold mb-8" justify="center">
              {{ $t("changePassword") }}
            </v-row>
            <v-row class="d-flex flex-column">
              <v-label class="text-left text-subtitle-1 font-weight-bold mb-2">
                {{ $t("newPassword") }}
              </v-label>
              <v-text-field
                filled
                v-model="password"
                id="new_password"
                :placeholder="$t('password')"
                :type="visiblePassword ? 'text' : 'password'"
                :append-icon="visiblePassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                @click:append="visiblePassword = !visiblePassword"
              />
            </v-row>
            <v-row class="d-flex flex-column">
              <v-label class="text-left text-subtitle-1 font-weight-bold mb-2">
                {{ $t("confirmPassword") }}
              </v-label>
              <v-text-field
                filled
                v-model="confirmation"
                id="confirm-password"
                :placeholder="$t('password')"
                :type="visiblePassword ? 'text' : 'password'"
                :append-icon="visiblePassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                @click:append="visiblePassword = !visiblePassword"
              />
            </v-row>
            <v-row class="my-8 text-subtitle-1 font-weight-medium" align="center">
              <v-checkbox class="font-weight-medium"></v-checkbox>
              {{ $t("rememberMe") }}
            </v-row>
            <v-row class="my-8">
              <v-btn block color="primary" id="reset-password" type="button">{{ $t("resetPassword") }}</v-btn>
            </v-row>
            <v-row class="text-subtitle-1" justify="center">
              {{ $t("alreadyHaveAccount") }}
              <router-link
                to="/login"
                class="text-decoration-none font-weight-bold fs-14 ml-6"
                id="sign-up-link"
              >
                {{ $t("logintoyouraccount") }}
              </router-link>
            </v-row>
          </v-form>
        </ValidationObserver>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import makeAuthService from '@/services/api/auth';
export default {
  data() {
    return {
      confirmation: '',
      password: '',
      token: null,
      visiblePassword: false,
    };
  },
  beforeCreate() {
    this.token = this.$route.query.token;
    const authService = makeAuthService(this.$api);
    authService
      .verifyEmail(this.token)
      .then(response => {
        if (response.status === 200) {
          this.$swal({
            icon: 'success',
            title: this.$t('verifiedResetlink'),
            timer: 2000,
          });
        }
      })
      .catch(error => {
        this.$swal({
          icon: 'error',
          title: this.$t('error'),
          text: error,
          timer: 2000,
        }).then(() => {
          this.$router.push('/login').catch(e => {
            console.log(e);
          });
        });
      });
  },
  methods: {
    resetPassword() {
      this.token = this.$route.query.token;
      const authService = makeAuthService(this.$api);
      authService
        .resetPassword({
          token: this.token,
          password: this.password,
        })
        .then(
          response => {
            this.$router.push('/login').catch(e => {
              console.log(e);
            });
            this.$swal({
              title: response.data.message,
              icon: 'success',
              showConfirmButton: false,
              position: 'center',
              timer: 2000,
              toast: true,
            });
          },
          error => {
            this.$swal({
              icon: 'error',
              title: this.$t('error'),
              position: 'center',
              text: error.response.data.error,
              timer: 2000,
            }).then(() => {
              this.$router.push('/login').catch(e => {
                console.log(e);
              });
            });
          }
        );
    },
  },
};
</script>
